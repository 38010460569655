import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const BouncingDrop = ({ title, content, delay, light, icon }) => {
  const dropRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    // L'animazione si attiva quando l'elemento è 100px sopra la vista
    gsap.fromTo(dropRef.current,
      {
        y: -1000,  // Posizione iniziale (fuori schermo, sopra)
        scaleY: 1.5,  // Effetto "goccia"
        scaleX: 0.8
      },
      {
        y: 0,  // Arriva alla posizione originale
        scaleY: 1,  // Tornando alla forma originale
        scaleX: 1,
        ease: "bounce.out",  // Effetto rimbalzo
        duration: 1.5,
        delay: 0.5 * delay,
        scrollTrigger: {
          trigger: dropRef.current,
          start: "top+=100px bottom",  // 100px prima che l'elemento entri in vista
          toggleActions: "play none none none",  // Solo riproduzione all'entrata
        }
      });

    // Anima l'elemento con GSAP quando lo scroll arriva a 100px sopra l'elemento
    gsap.fromTo(
      textRef.current,
      { y: 100, opacity: 0 }, // Posizione di partenza: 100px sotto e invisibile
      {
        y: 0,   // Posizione finale: torna a 0
        opacity: 1,  // Reso visibile
        duration: 1, // Durata dell'animazione
        delay: 0.5 * delay + 0.5,
        ease: 'power2.out', // Easing per un'animazione fluida
        scrollTrigger: {
          trigger: textRef.current, // L'elemento su cui scatenare l'animazione
          start: 'top-=50px bottom', // Inizia 100px sopra l'elemento
          toggleActions: 'play none none none', // Gioca l'animazione solo una volta
        }
      }
    );

  }, [delay]);


  return (
    <div className='bouce--inner--container'>
      <div
        ref={dropRef}
        style={{
          width: '100px',
          height: '100px',
          backgroundColor: light ? '#FFF' : '#3498db',
          borderRadius: '50%',  // Forma rotonda
          margin: '0px auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent:'center'
        }}
      >
        {icon}
      </div>
      <div style={{ overflow: 'hidden' }}>
        <div ref={textRef} style={{ transform: 'translateY(100%)' }}>
          <h4 className={`text-medium text--center ${light ? 'text--white' : null} `}>{title}</h4>
          {content &&
            <p className={`text-small text--center ${light ? 'text--white' : null} `}>{content}</p>
          }
        </div>
      </div>
    </div>
  );
};

export default BouncingDrop;
