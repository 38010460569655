import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Text = ({ content, delay,light }) => {
  const textRef = useRef(null);

  useEffect(() => {
    // Anima l'elemento con GSAP quando lo scroll arriva a 100px sopra l'elemento
    gsap.fromTo(
      textRef.current,
      { y: 100, opacity: 0 }, // Posizione di partenza: 100px sotto e invisibile
      {
        y: 0,   // Posizione finale: torna a 0
        opacity: 1,  // Reso visibile
        duration: 1, // Durata dell'animazione
        delay: delay,
        ease: 'power2.out', // Easing per un'animazione fluida
        scrollTrigger: {
          trigger: textRef.current, // L'elemento su cui scatenare l'animazione
          start: 'top+=100px bottom', // Inizia 100px sopra l'elemento
          toggleActions: 'play none none none', // Gioca l'animazione solo una volta
        }
      }
    );
  }, [delay]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <p className={`text-medium ${light ? 'text--white' : null} `} ref={textRef} style={{ transform: 'translateY(100%)' }} dangerouslySetInnerHTML={{ __html: content }}/>
    </div>
  );
};

export default Text;
