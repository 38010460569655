import React, { useState, useRef, useEffect } from 'react';
import { gsap } from 'gsap';

const Column = ({ index, title, description, isActive, handleClick, isMobile }) => {
  const columnRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      gsap.to(columnRef.current, { flex: 5, alignItems: 'flex-start', duration: 0.3, ease: 'power2.inOut', delay: 0.5 });
      gsap.to(titleRef.current, { writingMode: 'horizontal-tb', duration: 0.5, delay:0.5 });
      gsap.to(descriptionRef.current, { height: 'auto', opacity: 1, delay: 0.3, duration: 0.3 });
    } else {
      gsap.to(columnRef.current, { flex: 1, alignItems: !isMobile ? 'center': 'flex-start', duration: 0.3, ease: 'power2.inOut',delay:0.5 });
      gsap.to(titleRef.current, { writingMode: !isMobile ? 'vertical-rl' : 'horizontal-tb', duration: 0.5, delay: 0.5 });
      gsap.to(descriptionRef.current, { height: 0, opacity: 0, duration: 0.3 });
    }
  }, [isActive, isMobile]);

  return (
    <div
      className={`plus--column ${isActive ? 'active' : ''}`}
      ref={columnRef}
      onClick={() => handleClick(index)}
    >
      <h2 className="title" ref={titleRef}>
        {title}
      </h2>
      <p className="description text-medium" ref={descriptionRef}>
        {description}
      </p>
    </div>
  );
};

const App = ({ vantaggi, isMobile }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Chiudi se è già aperta
    } else {
      setActiveIndex(index); // Apri la colonna cliccata
    }
  };

  return (
    <div className="columns-container">
      {vantaggi.map((v,i) => (
        <Column
          key={i}
          index={i}
          title={v.title}
          description={v.content}
          isActive={activeIndex === i}
          handleClick={handleClick}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
};

export default App;
