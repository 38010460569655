import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import Lenis from '@studio-freight/lenis'; // Assicurati di importare Lenis e GSAP correttamente
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { preloadImages } from './utils/utils';
import Title from './components/title'
import SubTitle from './components/subTitle'
import Text from './components/text'
import BouncingDrop from './components/bouncingDrop'
import BouncingDropList from './components/bouncingDropList'
import Plus from './components/plus'
import { GiRapidshareArrow } from "react-icons/gi";
import { RiCommunityFill } from "react-icons/ri";
import { RiTreeFill } from "react-icons/ri";
import { MdOutlineDirectionsBike } from "react-icons/md";
import { MdEnergySavingsLeaf } from "react-icons/md";
import { BsBuildingFillUp } from "react-icons/bs";
import { MdOutlineRecycling } from "react-icons/md";
import { GiGreenhouse } from "react-icons/gi";


const vantaggi = [
  {
    title: 'Risparmio Economico',
    content: 'Riduzione delle bollette energetiche grazie all\'autoconsumo e alla condivisione dell\'energia'
  },
  {
    title: 'Comunità',
    content: 'Rafforzamento del tessuto sociale locale attraverso la collaborazione e la condivisione delle risorse'
  },
  {
    title: 'Autonomia energetica',
    content: 'Le comunità possono produrre e gestire la propria energia, riducendo la dipendenza da fornitori esterni'
  },
  {
    title: 'Riduzione dei costi',
    content: 'Condividere risorse e infrastrutture può abbattere i costi energetici per i membri della comunità'
  },
  {
    title: 'Sviluppo locale',
    content: 'Creano posti di lavoro e stimolano l\'economia locale attraverso investimenti in tecnologie verdi e nella costruzione di impianti'
  },
  {
    title: 'Coinvolgimento della comunità',
    content: 'Promuovono la partecipazione attiva dei cittadini nella gestione dell’energia, aumentando la consapevolezza e la responsabilità ambientale'
  },
  {
    title: 'Innovazione tecnologica',
    content: 'Possono incentivare l\'adozione di nuove tecnologie e pratiche sostenibili'
  },
  {
    title: 'Resilienza energetica',
    content: 'Riducono la vulnerabilità a crisi energetiche e fluttuazioni dei prezzi, garantendo un approvvigionamento più stabile'
  },
  {
    title: 'Benefici sociali',
    content: 'Possono contribuire a migliorare la qualità della vita e favorire l\'inclusione sociale'
  },
]


gsap.registerPlugin(ScrollTrigger);

function App() {

  const lenisRef = useRef(null); // Reference to store the Lenis object
  const contentElementsRef = useRef([]); // Reference for content elements
  const [isMobile, setIsMobile] = useState(false)



  const what = [
    {
      title: 'Produzione e Condivisione',
      content: 'Generano energia pulita da fonti come pannelli solari e turbine eoliche distribuita tra i membri della comunità.',
      icon: <GiRapidshareArrow color={!isMobile ? 'white' : '#0092C3'} fontSize={!isMobile ? 30 : 25} />
    },
    {
      title: 'Autoconsumo',
      content: 'L\'energia è utilizzata direttamente dai membri della comunità, riducendo la dipendenza dalla rete elettrica nazionale e abbassando i costi energetici.',
      icon: <RiCommunityFill color={!isMobile ? 'white' : '#0092C3'} fontSize={!isMobile ? 30 : 25} />
    },
    {
      title: 'Benefici Ambientali e Sociali',
      content: 'Promuovono la sostenibilità ambientale e offrono benefici economici e sociali alla comunità locale.',
      icon: <RiTreeFill color={!isMobile ? 'white' : '#0092C3'} fontSize={!isMobile ? 30 : 25} />
    },
  ]

  const strategie = [
    {
      title: 'Mobilità Sostenibile',
      content: 'Promozione di trasporti pubblici e biciclette',
      icon: <MdOutlineDirectionsBike color={!isMobile ? '#0092C3' : 'white'} fontSize={!isMobile ? 30 : 25} />
    },
    {
      title: 'Efficienza Energetica',
      content: 'Miglioramento dell\'efficienza energetica degli edifici pubblici',
      icon: <BsBuildingFillUp color={!isMobile ? '#0092C3' : 'white'} fontSize={!isMobile ? 30 : 25} />
    },
    {
      title: 'Energie Rinnovabili',
      content: 'Investimenti in solare e altre forme di energia sostenibile',
      icon: <MdEnergySavingsLeaf color={!isMobile ? '#0092C3' : 'white'} fontSize={!isMobile ? 30 : 25} />
    },
    {
      title: 'Gestione dei Rifiuti',
      content: 'Implementazione di sistemi di riciclaggio e compostaggio',
      icon: <MdOutlineRecycling color={!isMobile ? '#0092C3' : 'white'} fontSize={!isMobile ? 30 : 25} />
    },
    {
      title: 'Educazione Ambientale',
      content: 'Iniziative per sensibilizzare i cittadini sull\'importanza della sostenibilità',
      icon: <GiGreenhouse color={!isMobile ? '#0092C3' : 'white'} fontSize={!isMobile ? 30 : 25} />
    },
  ]


  function checkViewportSize() {
    if (window.innerWidth < 780) {
      setIsMobile(true)
      // Qui puoi aggiungere altre azioni da eseguire se la viewport è minore di 780px
    } else {
      setIsMobile(false)
    }
  }

  window.addEventListener('resize', checkViewportSize);
  window.addEventListener('load', checkViewportSize);

  useEffect(() => {
    // Function to initialize smooth scrolling
    const initSmoothScrolling = () => {
      // Initialize Lenis with properties
      lenisRef.current = new Lenis({
        lerp: 0.2,
        smoothWheel: true
      });

      // Update ScrollTrigger on every scroll event
      lenisRef.current.on('scroll', () => ScrollTrigger.update());

      // Recursive requestAnimationFrame loop for smooth scrolling
      const scrollFn = (time) => {
        lenisRef.current.raf(time);
        requestAnimationFrame(scrollFn);
      };

      requestAnimationFrame(scrollFn);
    };

    // Function to handle scroll-triggered animations
    const scrollAnimations = () => {
      const contentElements = contentElementsRef.current;
      const totalContentElements = contentElements.length;

      contentElements.forEach((el, position) => {
        const isLast = position === totalContentElements - 1;

        gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: 'top top',
            end: '+=100%',
            scrub: true
          }
        })
          .to(el, {
            ease: 'none',
            startAt: { filter: 'brightness(100%)' },
            filter: isLast ? 'none' : 'brightness(70%)',
            scale: 0.95,
            borderRadius: 40
          }, 0);
      });
    };

    // Initialization function
    const init = () => {
      initSmoothScrolling();
      scrollAnimations();
    };

    // Preload images before initializing scrolling and animations
    preloadImages('.content__img').then(() => {
      document.body.classList.remove('loading');
      init();
    });

    // Clean-up function to destroy Lenis and ScrollTrigger instances
    return () => {
      if (lenisRef.current) {
        lenisRef.current.destroy();
      }
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <main>
      <div className="content content--highlight content--intro">
        <div className="inner--container center">
          <div>
            <SubTitle light={true} content="Comunità energetica" delay={0} />
            <Title light={true} content="San Giacomo degli Schiavoni" delay={0.5} />
          </div>
        </div>
      </div>
      <div className="wrap">
        <div className="content content--sticky content--grid bg-1" ref={el => contentElementsRef.current[0] = el}>
          <div className="inner--container column sm--no--flex">
            <Title content="Cos'è una CER" delay={0} />
            <Text
              content="<p>Le <strong>Comunità Energetiche Rinnovabili (CER)</strong> offrono un modello innovativo di produzione e consumo di energia, coinvolgendo cittadini, imprese, enti locali e altre organizzazioni. </p><p>Questo approccio collaborativo consente di generare e utilizzare energia da fonti rinnovabili, portando vantaggi concreti a livello ambientale, economico e sociale. Ecco alcuni aspetti chiave che rendono le <strong>CER</strong> un pilastro della transizione energetica.</p>"
              delay={0.5}
            />
            {!isMobile ?
              <div className='bouce--container'>
                {what.map((el, i) => (
                  <BouncingDrop key={`bouncing-${i}`} title={el.title} content={el.content} delay={i} icon={el.icon} />
                ))}
              </div>
              :
              what.map((el, i) => (
                <BouncingDropList key={`bouncing-${i}`} title={el.title} content={el.content} delay={i} icon={el.icon} />
              ))

            }
          </div>
        </div>
        <div className="content content--sticky content--grid bg-2" style={{ padding: 0 }} ref={el => contentElementsRef.current[1] = el}>
          {/* <Title content="I vantaggi" delay={0} /> */}
          <Plus vantaggi={vantaggi} isMobile={isMobile} />
        </div>
        <div className="content content--sticky content--grid bg-1" ref={el => contentElementsRef.current[2] = el}>
          <div className="inner--container column">
            <Title content="Chi siamo" delay={0} />
            <Text
              content="<p>San Giacomo degli Schiavoni ha vinto la quinta call dell'<strong>EUCF</strong> (European City Facility), un'iniziativa dell'Unione Europea che supporta i comuni nella transizione verso l'energia sostenibile. Grazie all'EUCF, il comune molisano potrà sviluppare un piano di investimento per creare una <strong>CER</strong> (Comunità Energetica Rinnovabile). </p> <p>L'obiettivo è ridurre le emissioni di CO₂ e i costi energetici, promuovendo la sostenibilità.</p><p> L'EUCF aiuta piccoli comuni come San Giacomo a superare ostacoli finanziari e tecnici, rendendo i progetti di energia pulita più bancabili e attraenti per finanziamenti pubblici e privati.</p>"
              delay={0.5}
            />
          </div>
        </div>
        <div className="content content--sticky content--grid bg-2" ref={el => contentElementsRef.current[3] = el}>
          <div className="inner--container column sm--no--flex">
            <Title content="Obiettivo neutralità climatica" delay={0} light={true} />
            <Text
              light={true}
              content="<p>Il Comune di San Giacomo degli Schiavoni sta implementando strategie per raggiungere la <strong>neutralità carbonica</strong> e bilanciare le emissioni di gas serra prodotte con quelle rimosse dall'atmosfera attraverso il <strong>rimboschimento</strong>, l'uso di <strong>energie rinnovabili</strong> e l'acquisto di <strong>crediti di carbonio</strong>. Queste azioni mirano a ridurre l'impatto ambientale, promuovendo la <strong>sostenibilità</strong> e contribuendo all'obiettivo di diventare <em>carbon neutral</em>.</p>"
              delay={0.5}
            />
            {!isMobile ?
              <div className='bouce--container'>
                {strategie.map((el, i) => (
                  <BouncingDrop key={`bouncing-${i}`} title={el.title} content={el.content} delay={i} light={true} icon={el.icon} />
                ))}
              </div>
              :
              strategie.map((el, i) => (
                <BouncingDropList key={`bouncing-${i}`} title={el.title} content={el.content} delay={i} light={true} icon={el.icon} />
              ))
            }
          </div>
        </div>
        {/* <div className="content content--sticky content--grid bg-3" ref={el => contentElementsRef.current[4] = el}>
          <img className="content__img content__img--large content__img--left" src="img/12.png" />
          <h2 className="content__title"><i>The</i> Opulence</h2>
          <p className="content__text content__text--left text-meta">"The Opulence" epitomizes the cognitive elite's wealth in the algorithmic society, where opulent thoughts and experiences shape the societal narrative.</p>
        </div> */}
      </div>
      <div className="content content--highlight content--outro">
        <div className="inner--container column sm--no--flex">
          <Title content="Unisciti a noi" delay={0} />
        </div>
      </div>
      <footer className="frame frame--footer">
        <p className="frame__author"><span>Made by <a href="https://www.apio.cc">Apio</a></span> <span><a href="mailto:info@apio.com">Hire us</a></span></p>
      </footer>
    </main>
  );
}

export default App;
